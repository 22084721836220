/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2020-10-15 09:44:12
 * @LastEditors: Deng
 * @LastEditTime: 2021-07-15 17:19:53
 */
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule,
  MutationAction
} from 'vuex-module-decorators'
import store from '@/store'
import {
  getOrderNew,
  handlePayAliPay,
  handlePayWeChatH5
} from '@/pages/index/api/payment'
import { getChargeList } from '@/pages/user/api'
import { Toast } from 'vant'
import { WalletModule } from '@/store/modules/wallet'

@Module({ dynamic: true, store, name: 'Payment' })
class Payment extends VuexModule {
  /**
   * 充值账号
   */
  itunesInfo: UserInfo = {}
  /**
   * 是否支付成功
   */
  isPaySuccess = 0
  /**
   * 产品套餐
   */
  productList: ChargeRule[] = []
  /**
   * 默认选择的套餐索引
   */
  product_index = 0
  /**
   * 默认选择的支付套餐索引值
   * - 1微信
   * - 2支付宝
   */
  pay_index = 1
  /**
   * info.recharge_tips
   */
  rechargeTips = ''

  get isShowComponent() {
    return Object.prototype.hasOwnProperty.call(this.itunesInfo, 'id')
  }

  get customInputValue() {
    return WalletModule.customInputValue
  }

  /**
   * 判断是否支付成功
   */
  get isPayOk() {
    return this.isPaySuccess === 1
  }

  @Mutation
  setProductIndex(index: number) {
    this.product_index = index
  }
  /**
   * 设置用户选择套餐
   * @param index
   */
  @Mutation
  setPayIndex(index: number) {
    this.pay_index = index
  }

  @Mutation
  cleanUser() {
    this.itunesInfo = {}
  }

  /**
   * 设置充值用户信息
   * @param info
   */
  @Mutation
  setUser(info: UserInfo) {
    this.itunesInfo = info
    console.log('this.itunesInfo', this.itunesInfo)
  }

  /**
   * 用户充值
   */
  @Action({ rawError: true })
  async pay(front?: number) {
    console.log('pay front', front)
    const item = this.productList[this.product_index]
    const { type = '', timelimit_money = '' } = item
    let obj = {
      uid: this.itunesInfo.id || this.itunesInfo.user_id,
      changeid: item.id,
      coin: item.coin,
      money: item.money,
      front
    }

    if (!obj.uid) {
      Toast({
        message: '账号不能为空,请重新输入'
      })
      return
    }

    if (type === '2') {
      obj = {
        ...obj,
        money: timelimit_money
      }
    }
    if (item.id == '0') {
      obj = {
        ...obj,
        coin: String(Number(this.customInputValue) * 10),
        money: this.customInputValue
      }
    }

    if (obj.money == '0.00' || Number(obj.money) <= 0) {
      WalletModule.setCustomInputStatus(true)
      Toast('请输入金额')
      return
    }

    // 自定义加载图标
    Toast.loading({
      message: '正在充值',
      forbidClick: true,
      loadingType: 'spinner',
      duration: 0
    })

    try {
      if (WalletModule.payType === 1) {
        await this.payWeChatH5(obj)
      } else if (WalletModule.payType === 2) {
        await this.payAliPay(obj)
      }
    } catch (error) {
      Toast.clear()
    }
    Toast.clear()
  }

  @Action
  async payWeChatH5(obj: any) {
    //微信
    console.log('payWeChatH5', obj)
    try {
      const weiInfo = await handlePayWeChatH5(obj, obj.front)
      const {
        data: { mweb_url = '' }
      } = weiInfo
      if (weiInfo.data.code == -1) {
        WalletModule.setShowMethod(false)
        WalletModule.setShowCardMethod(false)
        WalletModule.setChargeLimit(weiInfo.data.msg)
        WalletModule.setShowLimitPop(true)

        return false
      }
      sessionStorage.setItem('onec', '1')
      setTimeout(() => {
        window.location.href = mweb_url
      }, 500)
    } catch (error) {
      console.error(error)
    }
  }

  @Action
  async payAliPay(obj: any) {
    //支付宝
    try {
      const aliPay = await handlePayAliPay(obj, obj.front)
      const {
        data: { html = '' }
      } = aliPay
      if (aliPay.data.code == -1) {
        WalletModule.setShowMethod(false)
        WalletModule.setShowCardMethod(false)
        WalletModule.setChargeLimit(aliPay.data.msg)
        WalletModule.setShowLimitPop(true)
        return false
      }
      sessionStorage.setItem('onec', '1')
      const _a = document.createElement('div')
      _a.innerHTML = html
      document.body.append(_a)
      setTimeout(() => {
        document.forms[0].submit()
      }, 500)
    } catch (error) {
      console.error(error)
    }
  }

  /**
   * 查询订单号
   */
  @Action
  async getOrder(orderno: string) {
    const orderInfo = await getOrderNew(orderno)
    const {
      code,
      data: { state = 0 }
    } = orderInfo
    console.log('getorder', code, orderInfo)
    if (code == '200') {
      sessionStorage.clear()
      this.setIsPaySuccess(state)
    } else {
      Toast({
        message: '网络错误,请稍后重试'
      })
    }
  }

  @Mutation
  setIsPaySuccess(status: number) {
    this.isPaySuccess = status
  }

  @MutationAction({
    mutate: ['productList']
  })
  async initProductList(uid?: string) {
    const { data } = await getChargeList(uid, 1)
    console.log('init', data.list)
    data.list.forEach((item: any) => {
      item.game_vip_name = data.game_vip_name
    })
    return {
      productList: data.list
    }
  }
}

export const PaymentModule = getModule(Payment)
