/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2020-09-07 11:58:13
 * @LastEditors: Yi Yunwan
 * @LastEditTime: 2021-03-31 10:54:50
 */
import {
  VuexModule,
  Module,
  Mutation,
  getModule,
  Action
} from 'vuex-module-decorators'
import store from '@/store'
import { changChargeSet } from '_user/api'
@Module({ dynamic: true, store, name: 'Wallet' })
class Wallet extends VuexModule {
  customInputStatus = false
  customInputValue = ''
  payType = 2 //1微信2支付宝
  showLimitPop = false
  cardRevicePop = false
  chargeLimit = 0
  showMethod = false //普通支付
  showCardMethod = false //周月卡支付
  showDayGiftMethod = false //每日礼包支付
  showCompensatMethod = false //包赔卡支付
  @Mutation
  setCustomInputStatus(val: boolean) {
    this.customInputStatus = val
  }

  @Mutation
  setCustomInputValue(val: string) {
    this.customInputValue = val
  }

  @Mutation
  setPayType(val: number) {
    this.payType = val
  }
  @Action
  setPayTypeActicon(val: number) {
    this.setPayType(val)
    changChargeSet({ charge: val })
  }

  @Mutation
  setShowLimitPop(val: boolean) {
    this.showLimitPop = val
  }

  @Mutation
  setCardRevicePop(val: boolean) {
    this.cardRevicePop = val
  }

  @Mutation
  setChargeLimit(val: number) {
    this.chargeLimit = val
  }

  @Mutation
  setShowMethod(val: boolean) {
    this.showMethod = val
  }
  @Mutation
  setShowCardMethod(val: boolean) {
    this.showCardMethod = val
  }
  @Mutation
  setShowDayGiftMethod(val: boolean) {
    this.showDayGiftMethod = val
  }
  @Mutation
  setShowCompensatMethod(val: boolean) {
    this.showCompensatMethod = val
  }
}

export const WalletModule = getModule(Wallet)
