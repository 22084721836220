

























































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import { compare as compareVersion, isIOS } from '@/common/utils'
// import { isIOS } from '@/common/utils'
import { WalletModule } from '@/store/modules/wallet'
import { PaymentModule } from '@/pages/index/modules/payment'
import { getAppLanguage } from '@/common/utils'
import { CommonModule } from '@/store/modules/common'
@Component
export default class extends Vue {
  @Prop({
    default: false,
    type: Boolean
  })
  show!: boolean
  @Watch('show')
  onShowChange(val: boolean) {
    this.curShow = val
  }
  curShow = this.show || false
  @Prop()
  productItem!: any

  @Prop({
    default: 1,
    type: Number
  })
  productType!: number //1.金币 2.月卡
  showWechatPay = false // 是否展示 微信支付 使用客户端呼起微信进行支付  1.0.5及以上的客户端才支持
  get payType() {
    return WalletModule.payType
  }
  @Watch('payType')
  onPayTypeChange(val: number) {
    this.curPayType = val
  }
  curPayType = this.payType
  submitLoading = false
  isIOS = isIOS
  language = getAppLanguage()
  get showGive() {
    if (this.productItem.type && this.productItem.is_first_charge) {
      return this.productItem.give
    } else if (this.productItem.type == 0 && this.productItem.give > 0) {
      return this.productItem.give
    } else {
      return 0
    }
  }
  mounted() {
    /**
     * 1.0.6及以上版本支持微信支持 否则隐藏微信支付
     * */
    // 判断是否大于1.0.5? 1.0.6及以上支持微信支付
    if (compareVersion(CommonModule.appVersion, '1.0.5')) {
      this.showWechatPay = true
    }
  }
  close() {
    this.$emit('close')
  }
  changePayType(val: number) {
    if (val === this.payType) return
    WalletModule.setPayType(val)
  }
  toAgreement() {
    window.location.href = '/h5/main/user.html#/richH5?id=81'
  }
  submit() {
    if (this.submitLoading) {
      return
    }
    this.submitLoading = true
    setTimeout(() => {
      this.submitLoading = false
    }, 2000)
    this.$emit('submit')
  }
}
