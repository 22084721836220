/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2021-03-29 15:33:59
 * @LastEditors: yaozuo
 * @LastEditTime: 2021-10-28 11:54:31
 */

import { isIOS } from '@/common/utils/device'
import { service, javaService } from '@/common/utils/request'
import { APP_VERSION } from '@/config'
import { CommonModule } from '@/store/modules/common'
import qs from 'qs'

/**
 * 账户注销
 * @returns
 */
export function submitNullfiy() {
  return service.post('/api/user/logout/submit')
}
/**
 * 上传图片
 * @param data
 * @returns
 */
export function uploadImage(data: FormData) {
  return service.post('api/v1/5/upload/file', data)
}

/**
 * 提交反馈
 * @param params
 * @returns
 */
export function submitFeedback(params: {
  model: string
  images: string
  content: string
}) {
  return service.post('/api/help/feedback/submit', qs.stringify(params), {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  })
}

/**
 * 问题分类列表
 * @returns
 */

/**
 * 问题列表
 * @returns
 */
export function helpProblemList(classId: string | (string | null)[]) {
  return service.get('/api/help/problem/list', {
    params: {
      class_id: classId
    }
  })
}
/**
 * 问题详情
 * @returns
 */
export function helpProblemDetail(id: string) {
  return service.get('/api/help/problem/detail', {
    params: {
      id
    }
  })
}
/**
 * ios 支付配置
 * @returns
 */
export function getIosConfig() {
  return service.get('/api/ios/pay/config')
}

//获取问题列表
export function getHelpList() {
  return service.get('/api/kigo/help/class/list')
}
//获取充值列表
export function getChargeList(uid?: string, front?: number) {
  return service.get(`/api/kigo/charge/list?uid=${uid}`, {
    headers: { 'app-front': front ? 1 : isIOS ? 2 : 3 }
  })
}

//设置充值软件
export function changChargeSet(data: { charge: number }) {
  return service.post('/api/kigo/charge/choose/set', qs.stringify(data))
}

/**
 * 获取充值明细
 * @param params
 * @returns
 */
export function getRechargeList(params: {
  uid: string | number
  token: string
}) {
  return service.get('/api/kigo/charge/record/list', { params })
}

/**
 * 获取富文本内容
 * @param params
 */
export function getRichTextContent(params: { id: string }) {
  return javaService.get('/kigo-user-api/protocol/getInfoById', { params })
}

/**
 * 抽奖
 * @param params
 * @returns
 */
export function getDrawLuckey(params: { uid: string }) {
  return javaService.get('/kigo-user-api/luck/draw', { params })
}

/**
 * 获取金豆和银豆兑换规则
 * @returns
 */
export function getExchangeRuleList() {
  return javaService.get('/kigo-room-api/kCoin/getExchangeRuleList')
}

/**
 * 兑换金豆或者银豆
 * @param params
 * @returns
 */
export function exchangeKCoinAndKBean(data: {
  exchangeRuleId: string | number
  uid: string | number
}) {
  return javaService.post(
    '/kigo-room-api/kCoin/exchangeKCoinAndKBean',
    qs.stringify(data),
    {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }
  )
}

/**
 * 获取充值兑换明细记录列表
 * @param params
 * @returns
 */
export function getCHageRecordList(params: { page: number; limit: number }) {
  return javaService.get('/kigo-user-api/pay/getChargeRecordList', { params })
}

/**
 * app端-邀请有礼邀请页数据获取
 * @param params
 * @returns
 */
export function getInviteInfo(params: { uid: string | number }) {
  return javaService.get('/kigo-game-server/invite/getInviteInfo', {
    params,
    headers: {
      'request-from': 'kigo-video-game'
    }
  })
}

/**
 * app端-在线客服列表获取
 * @param params
 * @returns
 */
export function getQuestionInfo(params: { effectType: number }) {
  return javaService.get('/kigo-game-server/customerService/getQuestionInfo', {
    params
  })
}

/**
 * app端-在线客服问题详情
 * @param params
 * @returns
 */
export function getQuestionDetail(params: { id: number | string }) {
  return javaService.get(
    '/kigo-game-server/customerService/getQuestionDetail',
    {
      params
    }
  )
}
/**
 * app端-接受邀请
 * @param data
 * @returns
 */
export function accessInvite(data: {
  inviteCode: string | number
  uid: string | number
}) {
  return javaService.post('/kigo-game-server/invite/accessInvite', data, {
    headers: {
      'request-from': 'kigo-video-game'
    }
  })
}

/**
 * app端-邀请分享页数据获取
 * @param params
 * @returns
 */
export function getShareInviteInfo(params: { uid: string | number }) {
  return javaService.get('/kigo-game-server/invite/getShareInviteInfo', {
    params,
    headers: {
      'request-from': 'kigo-video-game'
    }
  })
}

/**
 * 积分兑换-获取积分兑换配置
 * @param params
 * @returns
 */
export function getIntegralConfig(params: { uid: string | number }) {
  return javaService.get('/kigo-game-server/integral/getIntegralConfig', {
    params,
    headers: {
      'request-from': 'kigo-video-game'
    }
  })
}

/**
 * 兑换积分
 * @param data
 * @returns
 */
export function exchangeIntegral(data: {
  changeCoin: number
  uid: string | number
  useIntegral: number
}) {
  return javaService.post('/kigo-game-server/integral/exchangeIntegral', data, {
    headers: {
      'request-from': 'kigo-video-game'
    }
  })
}

/**
 * app端-获取游戏记录
 * @param params
 * @returns
 */
export function getUserGameRecord(params: {
  page: number
  limit: number
  uid?: string | number
}) {
  {
    return javaService.get('/kigo-game-server/integral/getUserGameRecord', {
      params,
      headers: {
        'request-from': 'kigo-video-game'
      }
    })
  }
}

/**
 * 我的会员-获取VIP信息详情
 * @returns
 */
export function getVipInfo() {
  return javaService.get('/kigo-game-server/user/getVipInfo')
}

/**
 * 查询最新版本信息android
 * @returns
 */
export function getLaestVserionInfo() {
  return javaService.get('/kigo-game-server/version/getLatestVersionInfo')
}

/**
 * 每日抽奖-获取抽奖次数&&轮播数据
 * @param params
 * @returns
 */
export function getDrawTimes(params: { uid: string | number }) {
  return javaService.get('/kigo-game-server/homeActivity/getDrawTimes', {
    params
  })
}

/**
 * 每日抽奖-抽奖
 * @param params
 * @returns
 */
export function everyDraw(params: { uid: string | number }) {
  return javaService.get('/kigo-game-server/homeActivity/everyDraw', { params })
}

/**
 * 每日抽奖-获取用户抽奖记录
 * @param params
 * @returns
 */
export function getUserDrawRecord(params: { uid: string | number }) {
  return javaService.get('/kigo-game-server/homeActivity/getUserDrawRecord', {
    params
  })
}

/**
 * 获取周六会员日信息
 * @returns
 */
export function getSaturdayVipDayInfo() {
  return javaService.get('/kigo-game-server/activity/getSaturdayVipDayInfo')
}

/**
 * 领取会员礼包
 * @returns
 */
export function receiveVipGiftPackage() {
  return javaService.get('/kigo-game-server/activity/receiveVipGiftPackage')
}

/**
 * app端-获取兑换商品列表
 * @param params
 * @returns
 */
export function getExchangeGoodsList(params: { limit: number; page: number }) {
  return javaService.get(
    '/kigo-game-server/exchangeShop/getExchangeGoodsList',
    { params }
  )
}

/**
 * app端-获取商品详情
 * @param params
 * @returns
 */
export function getGoodsDetail(params: { id: number | string }) {
  return javaService.get('/kigo-game-server/exchangeShop/getGoodsDetail', {
    params
  })
}
/**
 * app端-确认订单
 * @param params
 * @returns
 */
export function getConfirmOrderInfo(params: {
  goodsId: string | number
  uid?: string | number
}) {
  return javaService.get('/kigo-game-server/exchangeShop/verifyOrder', {
    params
  })
}

/**
 * app端-获取地址列表
 * @returns
 */
export function getAddressList() {
  return javaService.get('/kigo-game-server/exchangeShop/getAddressList')
}

/**
 * 获取可选择省市区
 * @returns
 */
export function getZoneList() {
  return javaService.get('/kigo-game-server/exchangeShop/getZone')
}

/**
 * app端-添加地址
 * @param data
 * @returns
 */
export function addAddress(data: {
  addressDetail: string
  userName: string
  userPhone: string
  zoneCode: any[]
}) {
  return javaService.post('/kigo-game-server/exchangeShop/addAddress', data)
}

/**
 * app端-修改地址
 * @param data
 * @returns
 */
export function updateAddress(data: {
  id: number | string
  addressDetail: string
  userName: string
  userPhone: string
  zoneCode: any[]
}) {
  return javaService.post('/kigo-game-server/exchangeShop/updateAddress', data)
}

/**
 * app端-删除地址
 * @param data
 * @returns
 */
export function deleteAddress(data: { id: number | string }) {
  return javaService.post('/kigo-game-server/exchangeShop/deleteAddress', data)
}

/**
 * app端-获取地址详情
 * @param params
 * @returns
 */
export function getAddressDetail(params: { id: number | string }) {
  return javaService.get('/kigo-game-server/exchangeShop/getAddressDetail', {
    params
  })
}

/**
 * app端-提交订单
 * @param data
 * @returns
 */
export function submitOrder(data: {
  addressId?: string | number
  goodsId: string | number
  goodsName: string
  goodsPic: string
  uid?: string | number
  useIntegral: number | string
  userPhone?: string
}) {
  return javaService.post('/kigo-game-server/exchangeShop/submitOrder', data)
}

/**
 * app端-获取订单列表
 * @param params
 * @returns
 */
export function getExchangeOrderList(params: { status: string | number }) {
  return javaService.get(
    '/kigo-game-server/exchangeShop/getOrderListByStatus',
    { params }
  )
}

/**
 * app端-获取订单详情
 * @param params
 * @returns
 */
export function getOrderDetail(params: { id: string | number }) {
  return javaService.get('/kigo-game-server/exchangeShop/getOrderDetail', {
    params
  })
}

/**
 * app端-获取微信客服
 * @returns
 */
export function getweChatInfo() {
  return javaService.get('/kigo-game-server/customerService/getweChatInfo')
}

/**
 * 获取钱包金币明细
 * @param params
 * @returns
 */
export function getCoinDetailList(params: { page: number; limit: number }) {
  return javaService.get('/kigo-game-server/home/getCoinDetailList', {
    params
  })
}

/**
 * app端-获取周卡月卡配置
 * @returns
 */
export function getUserVipCardConfig(uid?: string | number) {
  return javaService.get('/kigo-game-server/vipCard/getUserVipCardConfig', {
    params: { uid }
  })
}

/**
 * app端-每日免费领取周卡月卡
 * @param data
 * @returns
 */
export function cardReceiveCoin(data: { type: number; uid?: string | number }) {
  return javaService.post('/kigo-game-server/vipCard/h5ReceiveCoin', data)
}

/**
 * app端-获取banner图配置
 * @param params
 * @returns
 */
export function getBannerByType(params: { bannerType: number }) {
  return javaService.get('/kigo-game-server/home/getBannerByType', {
    params
  })
}

/**
 * 检测兑换次数是否已达上线
 * @param params
 * @returns
 */
export function checkBuyLimit(params: { goodsId: number | string }) {
  return javaService.get('/kigo-game-server/exchangeShop/checkBuyLimit', {
    params
  })
}

/**
 * app端-活动-等级相关
 * @param params
 * @returns
 */
export function getUseCoinLevelInfo(params: {
  activityType: number
  uid?: string | number
}) {
  return javaService.get('/kigo-game-server/activity/getUseCoinLevelInfo', {
    params
  })
}

/**
 * 查询最新版本信息android
 * @returns
 */
export function getLatestVersionInfo() {
  return javaService.get('/kigo-game-server/version/getLatestVersionInfo')
}

/**
 * app端-邀请有礼邀请页数据获取
 * @param params
 * @returns
 */
export function getInviteActivityInfo(params: { uid: string | number }) {
  return javaService.get('/kigo-game-server/inviteWithdraw/getInviteInfo', {
    params,
    headers: {
      'request-from': 'kigo-video-game'
    }
  })
}

/**
 * web-微信授权
 * @param data
 * @returns
 */
export function wechatAuth(data: {
  code: string
  uid: string | number
  taskBind?: number
}) {
  return javaService.post(
    '/kigo-game-server/inviteWithdraw/wechatAuth',
    qs.stringify(data),
    {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }
  )
}

/**
 * web-提现列表
 * @param params
 * @returns
 */
export function withdrawList(params: {
  page: number
  limit: number
  uid: string | number
}) {
  return javaService.get('/kigo-game-server/inviteWithdraw/withdrawList', {
    params
  })
}

/**
 * web-邀请列表
 * @param params
 * @returns
 */
export function getInviteList(params: {
  page: number
  limit: number
  uid: string | number
}) {
  return javaService.get('/kigo-game-server/inviteWithdraw/inviteList', {
    params
  })
}

/**
 * web-平台提现列表
 * @param params
 * @returns
 */
export function platformWithdrawList(params: { limit: number; page: number }) {
  return javaService.get(
    '/kigo-game-server/inviteWithdraw/platformWithdrawList',
    {
      params
    }
  )
}

/**
 * web-手动提现
 * @param data
 * @returns
 */
export function withdraw(data: { uid: string | number; withdrawId: string }) {
  return javaService.post(
    '/kigo-game-server/inviteWithdraw/withdraw',
    qs.stringify(data),
    {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }
  )
}

/**
 * app-获取所有座位信息
 * @returns
 */
export function getAllMachine() {
  return javaService.get('/kigo-game-server/game/getAllMachine')
}

/**
 * h5端-下机
 * @param data
 * @returns
 */
export function commitOffMic(data: {
  offMicPassword: string
  seatId: string | number
}) {
  return javaService.post('/kigo-game-server/game/offMic', data)
}

/**
 * 获取排行奖励配置
 * @param params
 * @returns
 */
export function getRankAwardConfig(params: { rankType: string }) {
  return javaService.get('/kigo-game-server/rankConfig/getRankAwardConfig', {
    params
  })
}

/**
 * 获取任务信息
 * @param params
 * @returns
 */
export function getTaskList(params: {
  playTaskType?: number | string
  uid?: string | number
  taskType: number | string
}) {
  return javaService.get('/kigo-game-server/homeSign/getTaskInfo', {
    params
  })
}

/**
 * 领取任务奖励
 * @param params
 * @returns
 */
export function receiveTaskAward(data: {
  taskId: string | number
  uid?: string | number
}) {
  return javaService.post('/kigo-game-server/homeSign/receiveTaskAward', data)
}

/**
 * 签到
 * @param data
 * @returns
 */
export function commitSign(data: {
  uid?: string | number
  day?: string | number
}) {
  return javaService.post('/kigo-game-server/homeSign/signForJson', data)
}
/**
 * 获取每日礼包充值列表
 * @returns
 */
export function getDayGiftRechargeList(params: any) {
  return javaService.get(
    '/kigo-game-server/everyday/gift/package/getRechargeList',
    { params }
  )
}

/**
 * 包赔卡配置查询
 * @param params
 * @returns
 */
export function getProtectBuyConfigs(params: any) {
  return javaService.get('/kigo-game-server/protect/getProtectBuyConfigs', {
    params
  })
}

/**
 * 获取推币机包机卡信息
 * @returns
 */
export function getPushCoinCharterInfo(params: any) {
  return javaService.get('/kigo-game-server/user/getPushCoinCharterInfo', {
    params
  })
}

/**
 * 一键领取所有任务
 * @param params
 * @returns
 */
export function receiveAllTaskByType(params: {
  //任务小类 如不传不区分 4会员升级任务 5会员登录任务 6累计充值天数 7累计充值金额 8会员消耗任务
  playTaskType?: number | string
  //任务大类 1日常 2vip
  taskType: number | string
  uid?: string
}) {
  return javaService.get('/kigo-game-server/homeSign/receiveAllTaskByType', {
    params
  })
}

/**
 * 测试版本更新
 * @returns
 */
export function throwVersionUpdate() {
  return javaService.get('/kigo-game-server/test/throwVersionUpdate')
}
