/*
 * @Descripttion:
 * @Author: Yi Yunwan
 * @Date: 2020-10-28 15:41:37
 * @LastEditors: Deng
 * @LastEditTime: 2021-07-15 17:11:54
 */
import { isAndroid } from '@/common/utils'
import { service, javaService } from '@/common/utils/request'
import { CommonModule } from '@/store/modules/common'
import md5 from 'js-md5'
import { isIOS } from '@/common/utils'
/**
 * 微信H5支付
 * @param params
 */
export function handlePayWeChatH5(params: any = {}, front?: number) {
  let curFront = isIOS ? 2 : 3
  if (front) {
    curFront = front
  }
  delete params.front
  return service
    .get('/api/v1/7/wechatH5', {
      params: {
        ...params,
        /**
         * 1-安卓 2-ios
         */
        device_type: isAndroid ? 1 : 2
      },
      headers: {
        'app-front': curFront
      }
    })
    .catch((error: IResponse) => {
      if (error.code == '200') {
        return Promise.resolve(error)
      } else {
        return Promise.reject(error)
      }
    }) as IRes
}

/**
 * 支付宝H5支付
 * @param params
 */
export function handlePayAliPay(params: any = {}, front?: number) {
  let curFront = isIOS ? 2 : 3
  if (front) {
    curFront = front
  }
  delete params.front
  return service
    .get('/api/v1/7/alipayH5', {
      params: {
        ...params,
        /**
         * 1-安卓 2-ios
         */
        device_type: isAndroid ? 1 : 2
      },
      headers: {
        'app-front': curFront
      }
    })
    .catch((error: IResponse) => {
      if (error.code == '200') {
        return Promise.resolve(error)
      } else {
        return Promise.reject(error)
      }
    }) as IRes
}

/**
 * 获取订单信息
 * @param orderno
 */
export function getOrder(orderno = ''): IRes {
  const str = md5(orderno + 'qazwsx123')
  return service
    .get('/api/v1/7/getOrder', {
      params: {
        sign: str,
        orderno
      }
    })
    .catch((error: IResponse) => {
      if (error.code == '200') {
        return Promise.resolve(error)
      } else {
        return Promise.reject(error)
      }
    }) as IRes
}

//充值用户搜索
export function chargeSearch(params: {
  keyword: string
  page: number
  page_size: number
}) {
  return service.get('/api/kigo/charge/search', { params })
}
/**
 * 新-java服务查询订单状态
 * @param orderId
 * @returns
 */
export function getOrderNew(orderId = '') {
  const timestamp = new Date().getTime()
  const str = md5(orderId + timestamp + 'qazwsx123')
  return javaService
    .get('/kigo-user-api/pay/order/query', {
      params: {
        sign: str,
        orderId,
        timestamp
      }
    })
    .catch((error: IResponse) => {
      if (error.code == '200') {
        return Promise.resolve(error)
      } else {
        return Promise.reject(error)
      }
    }) as IRes
}

/**
 * 三方游戏-创建订单
 * @param data
 * @returns
 */
export function createGamePayOrder(data: {
  gameOperation: string
  gamePartnerId: string
  gameProductId: string
  payMode: number
  productId: string
}) {
  return javaService.post(
    '/kigo-room-api/game/children/machine/createPayOrder',
    data
  )
}
